import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default ({ data, location, pageContext }) => (
    <Layout>
        <SEO
            pagetitle={`CATEGORY: ${pageContext.catname}`}
            pagedesc={`名古屋にあるデザインプロダクション／スタジオ・アオの「${pageContext.catname}」カテゴリーページ`}
            pagepath={location.pathname}
        />
        {/*記事一覧ページ*/}
        <main className="ArchivesPage">
            <section className="ArchivesPage__section1">
                <div className="l-column">
                    <div className="titleWrap">
                        <svg class="titleImg" version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 358.2 100.8" style={{ enableBackground: 'new 0 0 358.2 100.8' }} xmlSpace="preserve" fill="#6683a7">
                            <g>
                                <rect x="257.4" width="100.8" height="100.8" />
                                <circle cx="50.4" cy="50.4" r="50.4" />
                                <path d="M113.4,0c10,14.3,15.8,31.7,15.8,50.4s-5.8,36.1-15.8,50.4h115.2V0H113.4z" />
                            </g>
                        </svg>
                        <h2 className="title">BLOG <span>&amp;</span> LIST</h2>
                    </div>
                    <div className="BlogList">
                        <h3 className="BlogList__title">CATEGORY： {pageContext.catname}</h3>
                        <div className="BlogList__body">
                            <div className="BlogList__items">
                                {data.allWordpressPost.edges.map(({ node }) => (
                                    <article className="BlogList__item" key={node.id}>
                                        <div className="BlogList__itemInner">
                                            <Link to={`/blog/post/${node.slug}/`}>
                                                <figure className="BlogList__eyecatchWrap">
                                                    {/* アイキャッチ画像 */}
                                                    <Img fluid={node.featured_media ? node.featured_media.localFile.childImageSharp.fluid : data.noimage.childImageSharp.fluid} alt={node.featured_media ? node.featured_media.alt_text : `アイキャッチ画像`} />
                                                </figure>
                                            </Link>
                                            <Link to={`/blog/post/${node.slug}/`}>
                                                <h3 className="BlogList__itemTitle">{node.title}</h3>
                                            </Link>
                                            <p className="BlogList__itemText">
                                                {`${node.excerpt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').slice(0, 70)}`}  {/*htmlタグを取り除いた上で70文字までを表示*/}
                                                <span>
                                                    <Link to={`/blog/post/${node.slug}/`}>
                                                        [...]
                                                    </Link>
                                                </span>
                                            </p>
                                        </div>
                                    </article>
                                ))}
                            </div>
                            <div className="BlogList__foot">
                                <div className="Pagination">
                                    <ul className="Pagination__postlink">
                                        {!pageContext.isFirst && (
                                            <li className="Pagination__prev">
                                                <Link to={pageContext.currentPage === 2 ? `/cat/${pageContext.catslug}/` : `/cat/${pageContext.catslug}/${pageContext.currentPage - 1}/`} rel="prev">
                                                    <FontAwesomeIcon icon={faChevronLeft} className="Pagination__prevFontAwesomIcon" />
                                                    <span>前のページ</span>
                                                </Link>
                                            </li>
                                        )}

                                        {!pageContext.isLast && (
                                            <li className="Pagination__next">
                                                <Link to={`/cat/${pageContext.catslug}/${pageContext.currentPage + 1}/`} rel="next">
                                                    <span>次のページ</span>
                                                    <FontAwesomeIcon icon={faChevronRight} className="Pagination__nextFontAwesomIcon" />
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
)

export const query = graphql`
query($catid: String!, $skip: Int!, $limit: Int!) {
    allWordpressPost(sort: {order: DESC, fields: date}, skip: $skip, limit: $limit, filter: {categories: {elemMatch: {id: {eq: $catid }}}}) {
        edges {
          node {
            title
            id
            slug
            excerpt
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                alt_text
            }
          }
        }
    }
    noimage: file(relativePath: {eq: "common/noimage_825_510.png"}) {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`